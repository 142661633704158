#site-header
{
	padding-top: 25px;
	padding-bottom: 25px;
	width: 100%;
	margin: 0 auto;
	
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
}

#site-header.overlay
{
	position: absolute;
}
#site-header.gradient
{
	background-image: radial-gradient(ellipse farthest-corner at center bottom,#b3f8e8 0%,#b9f2e9 100%);
}
#site-header.solutions
{
	background: #fffcf3;
}
#site-header.blog
{
	background: #b9f2e9;
}
#site-header.catalog
{
	background: #868ab0;
}


#logo svg
{
	display: block;
	width: 70px;
}


@media screen and (min-width: 700px)
{
	#site-header
	{
		padding: 45px 60px;
		justify-content: flex-start;
	}
	
	#logo svg
	{
		display: block;
		width: 80px;
	}
}
@media screen and (min-width: 1000px)
{
	#logo svg
	{
		display: block;
		width: 85px;
	}
}
@media screen and (min-width: 1400px)
{
	#logo svg
	{
		display: block;
		width: 100px;
	}
}

.main-nav
{	
	display: flex;
	justify-content: space-between;	
	padding-top: 18px;

	ul
	{
		list-style: none;
		margin: 0;
		padding-left: 14px;
	}

	li
	{
		display: inline-block;
		margin-right: 18px;
		text-decoration: none;
		color: #443629;
		font-weight: 700;
		&:last-child
		{
			margin-right: 0;
		}
		&:hover
		{
			color: #fe6e36;
		}

		@media screen and (min-width: 500px)
		{
			font-size: calc(16px + 5 * ((100vw - 500px) / 1500));
		}
	}
}
