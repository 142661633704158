@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: local('Roboto'),
       local('Roboto-Regular'),
       url('/src/fonts/Roboto-regular/Roboto-regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: local('Roboto Bold'),
       local('Roboto-Bold'),
       url('/src/fonts/Roboto-700/Roboto-700.ttf') format('truetype');
}

@font-face {
  font-family: 'PT-Sans';
  font-weight: 400;
  font-style: normal;
  src: local('PT Sans'),
       local('PTSans-Regular'),
       url('/src/fonts/PT_Sans/PT_Sans-Web-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'PT-Sans';
  font-weight: 700;
  font-style: normal;
  src: local('PT Sans Bold'),
       local('PTSans-Bold'),
       url('/src/fonts/PT_Sans/PT_Sans-Web-Bold.ttf') format('truetype');
}



*
{
	font-family: 'Arial', sans-serif;
	color: #171717;
	box-sizing: border-box;
}
.fonts-loaded *
{
	font-family: 'Roboto', 'Arial', sans-serif;
}

html
{
	font-size: 16px;
	background: #fefefe;
	overflow-x: hidden;
	
	@media screen and (min-width: 500px)
	{
		font-size: calc(16px + 8 * ((100vw - 500px) / 1500));
	}
	@media screen and (min-width: 1000px)
	{
		font-size: 19px;
	}
}

body
{
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	background: #f5f5f5
}

body.error
{
	height: 100vh;
	background-image: radial-gradient(ellipse farthest-corner at center bottom,#b3f8e8 0%,#b9f2e9 100%);
	position: relative;
	text-align: center;
	h1
	{
		/*position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);*/
		margin: 50px auto;
		padding: 20px;
	}
}

p, table, li
{
	line-height: 1.5;
}

blockquote p
{
	border-left: 8px solid #fff2b4;
	background: #fffdf2;
	padding: 25px !important;
}

a
{
	text-decoration: none;
	color: inherit;
	font-family: inherit;
	transition: color;
	transition-duration: 0.10s;
	transition-timing-function: ease-out;
}

p a, 
.docs-wrapper main a,
.h-entry .description a
{
	color: #007ada;
}

p a, 
main li a,
.h-entry li a
{
	color: #007ada;
	background-image: linear-gradient(120deg, #ffee58 0%, #ffee58 100%);
	background-repeat: no-repeat;
	background-size: 100% 0.2em;
	background-size: 100% 30%;
	background-position: 0 90%;
}

.heading-link,
.description .heading-link,
.main .heading-link,
{
	position: absolute !important; 
	left: -25px;
	top: 0;
	padding-left: inherit;
}


svg #lnr-link
{
	path
	{
		fill: inherit;
	}
}
.heading-link
{
	svg
	{
		width: 20px;
		height: 20px;
	}
	use.link
	{
		fill: #ccc;
		transition: fill;
		transition-duration: 0.10s;
		transition-timing-function: ease-out;
	}
	&:hover use.link
	{
		fill: #007ada;
	}
}

h1:hover use.link,
h2:hover use.link,
h3:hover use.link,
h4:hover use.link,
h5:hover use.link,
h6:hover use.link
{
	fill: #007ada;
}

.bg-underline
{
	display: inline;
	background-image: linear-gradient(120deg, #fff2b4 0%, #fff2b4 100%); 
	background-repeat: no-repeat;
	background-size: 100% 30%;
	background-position: -20px 88%;
}

p>code,
li>code,
td>code
{
	background: #272822;
    padding: 0 5px 2px;
    border-radius: 4px;
    position: relative;
    top: -1px;
    margin: 0 3px 0 1px;
    font-size: 90%;
    overflow-wrap: break-word;
    word-wrap: break-word;
	
	color: #f8f8f2;
	text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}

.full-width
{
	padding: 20px;
	@media screen and (min-width: 640px) 
	{
		padding: 20px 60px;
	}
	@media screen and (min-width: 1500px) 
	{
		padding: 20px;
	}
}

.circle-img
{
	border-radius: 100%;
}

.section-title
{
	/*font-size: 3em;*/
	margin: 4px auto;
	max-width: 1300px;
}

/* Buttons */
.btn
{
	display: inline-block;
	padding: 4px 8px;
	padding: 8px 12px;
	border: none;
	background-color: #5b5b5b;
	border: 1px solid #5b5b5b;
	color: #fefefe;
	cursor: pointer;
	transition: background, border;
	transition-duration: 0.10s;
	transition-timing-function: ease-in-out;
}
.btn:hover
{
	background-color: #3e3e3e;
	border-color: #3e3e3e;
}

.btn.ghost
{
	border: 1px solid #2b2b2b;
	background-color: transparent;
	color: inherit;
}

.btn.download
{
	background-color: #1a66ff;
	border-color: #1a66ff;
}
.btn.download:hover
{
	background-color: #0055ff;
	border-color: #0055ff;
}

.btn.buy
{	
	background-color: #FF5722;
}
/*==*/


.hero-wrapper
{
	position: relative;
	min-height: 750px;
	min-height: 103vh;
	margin: 0 auto;
	overflow: hidden;
	background-image: radial-gradient(ellipse farthest-corner at center bottom,#b3f8e8 0%,#b9f2e9 100%);
	@media screen and (max-width: 700px) and (max-height: 565px)
	{
		min-height: 900px;
		min-height: 120vh;	
	}
	@media screen and (min-width: 700px)
	{
		min-height: 900px;
		min-height: 100vh;
	}


	img
	{
		position: absolute;
		bottom: -26px;		
		left: -10%;
		min-width: 500px;
		width: 120%;
		height: auto;

		@media screen and (min-width: 850px)
		{
			max-width: 1235px;
			width: 110%;
			height: auto;
			left: 50%;
			transform: translateX(-50%);
		}

		@media screen and (min-width: 850px) and (max-height: 745px)
		{
			width: 85%;
			max-width: 1100px;
		}
	}


	h1
	{
		position: absolute;
		display: inline;
		z-index: 99;
		
		font-size: calc(30px + 16 * ((100vw - 320px) / 1000));
		font-size: 2.4em;
		font-size: 1.5em;
		font-size: 1.1em;
		
		font-family: Arial, Helvetica, sans-serif;
		font-weight: 700;
		
		line-height: 1;
		color: #5c3e1d;
		color: #443629;
	
		padding: 15px 40px 15px 30px;
		background-color: #fff;
		
		border-top-left-radius: 35px;
		border-bottom-left-radius: 40px;
		border-top-right-radius: 24px;
		border-bottom-right-radius: 50px;
		border-bottom: 13px solid #afecf5;
		border-right: 13px solid #afecf5;
		
		right: 50%;
		top: 20%;
		transform: translateX(50%);
		white-space: nowrap;
		
		@media screen and (min-width: 400px)
		{
			font-size: 1.3em;
		}
		@media screen and (min-width: 650px)
		{
			top: 23%;
			right: 9%;
			transform: none;		
			font-size: 1.7em;
		}
		@media screen and (min-width: 1600px)
		{
			top: 28%;
			right: 12%;
			font-size: 2.4em;
			font-size: 2.1em;
			font-size: calc(24px + 16 * ((100vw - 320px) / 1000));
		}
	}

	#credit
	{
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 9;

		.credit__icon svg
		{
			cursor: pointer;
			width: 15px;

			circle
			{
				fill: #feb500;
			}
		}
		.credit__details
		{
			display: none;
			position: absolute;
			right: 18px;
			top: 4px;
			background-color: #6ed582;
			border-radius: 6px;
			color: #fff;
			padding: 4px 10px;
			font-size: 0.65em;
			a
			{
				display: block;
				white-space: nowrap;
			}
		}
		.credit__details.display
		{
			display: block;
		}
		@media screen and (min-width: 700px)
		{
			right: 40px;
			top: 70px;
	
			.credit__icon svg
			{
				width: 25px;
			}
			.credit__details
			{
				display: none;
				right: 30px;
				top: 1px;
				font-size: 0.75em;
			}
		}
	}
}
.secondary-fonts-loaded .hero-wrapper h1
{
	font-family: PT-Sans, Roboto, Arial, Helvetica, sans-serif;
}




.page-wrapper
{
	padding-top: 200px;
	padding-bottom: 20px;
	text-align: center;

	h1.title
	{
		font-weight: 700;
		text-align: center;
	}

	h1.title + p
	{
		max-width: 750px;
		margin: 0 auto;
		padding: 0 10px;
		@media screen and (min-width: 800px) 
		{
			padding: 0;	
		}
	}

	.action
	{
		margin: 50px auto;

		.btn
		{
			margin-right: 1%;
			width: 155px;
			&:last-child
			{
				margin-right: 0;
			}
		}
	}
}
.secondary-fonts-loaded .page-wrapper h1.title
{
	font-family: PT-Sans, Roboto, Arial, Helvetica, sans-serif;
}




/* Footer */
.site-footer
{
	padding: 60px 20px 60px 20px;
	background: #b87938;
	background: #363636;

	*
	{
		color: #fefefe;
	}

	nav
	{
		margin-bottom: 60px;
		ul
		{
			list-style: none;
			padding: 0;
		}
		
		li
		{
			margin-right: 8px;
			display: inline-block;
			font-size: 0.8em;
			&:last-child
			{
				margin-right: 0;
			}
			&:hover
			{
				color: #fe6e36;
			}
			a
			{
				color: inherit;
			}
		}
		
		
	}
}


.site-footer__inner
{
	max-width: 1300px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	text-align: center;
}


.site-footer__copyrights div
{
	font-size: 0.75em;
	&:last-child
	{
		margin-top: 4px;
	}
}


@media screen and (min-width: 650px)
{
	.site-footer__inner
	{
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		text-align: left;
	}
	
	.site-footer nav
	{
		margin-bottom: 0;
	}
}


span.token.php.language-php {
	color: #f8f8f8;
}

.sponsor-banner {
	text-align: center;
	padding: 20px;

	a {
		display: block;
		img {
			width: 100%;
			max-width: 728px;
			height: auto;
		}
	}
}


@import 'header';
@import 'pipits';
@import 'pipit-listing';
@import 'blog';
@import 'docs';
@import 'sponsor';