.sponsor {
    background-color: #e2fdfb;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(0deg, #effffe 0%, #ddffff 100%);
    padding: 1rem;
    margin-bottom: 50px;

    @media screen and (max-width: 700px) {
        margin-left: 6%;
        margin-right: 6%;
    }

	p {
        a {
            color: #ef5350;
        }
	}
}