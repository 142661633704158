.blog-header
{
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	font-size: 1.7em;
	@media screen and (min-width: 700px){
		font-size: 2em;
	}

	&.is-small {
		font-size: 1.5em;
	}
}
.blog-header.wide
{
	max-width: 1300px;
}

.blog-list
{
	background: #fffcf3;	
	padding-top: 60px;
	padding-bottom: 60px;
}
.blog-list.blue
{
	background: #b9f2e9;
}


.tags
{
	list-style: none;
	margin: 0;
	li
	{
		display: inline-block;
		margin-right: 8px;
		background-color: #fff2b4;
		&:last-child
		{
			margin-right: 0 !important;
		}
		&:hover
		{
			background-color: #ffe882;
		}

		a
		{
			color: #171717;
			background: none;
			padding: 6px 10px;
			display: block;
		}
	}
}


/* Grid listing */
.article-grid
{
	text-align: left;
	margin: 60px auto;
	
	max-width: 1300px;
	
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.article-grid__item
{
	display: inline-block;
	position: relative;
	
	width: 100%;
	min-height: 300px;
	
	margin-bottom: 2em;	
	background: #f5f5f5;
	background: #fffcf3;
	
	background: #fefefe;
	border: 1px solid #f1f0e7;
	border-radius: 12px;
	overflow: hidden;
	transition: border;
	transition-duration: 0.15s;
	transition-timing-function: ease-in-out;

	&:hover
	{
		border: 1px solid #ffe881;
	}


	header
	{
		padding: 0 25px;
		margin-top: 50px;
		margin-bottom: 30px;
	}
	
	h2
	{
		color: #443629;
		font-size: 24px;
	}

	p
	{
		text-align: left;
		padding: 0 30px 0 25px;
		font-size: 16px;
		@media screen and (min-width: 950px) and (max-width: 1050px)
		{
			font-size: 14px;
		}
	}
	

	.tags
	{	
		position: absolute;
		right: 0;
		bottom: 0;	
		display: flex;
		flex-wrap: nowrap;
		z-index: 9;
		li
		{
			margin-right: 2px;
			font-size: 16px;

			a
			{
				padding: 4px 10px;
			}
		}
	}
}

.article-grid__item.blue
{
	background-color: #ddffff;
	background-image: linear-gradient(0deg, #effffe 0%, #ddffff 100%);

	p a
	{
		color: #007ada;
		color: #ef5350;
	}
}

@media screen and (min-width: 700px)
{
	.article-grid__item
	{
		width: 49%;
	}
}
@media screen and (min-width: 950px)
{
	.article-grid__item
	{
		width: 32%;
		max-width: 420px;
	}
	
	.article-grid__item.wide
	{
		width: 66%;
		max-width: none;
	}
}

.form_input
{
	display: flex;
	padding: 0 30px 0 25px;
	width: 100%;
	align-items: center;
}


form.article-grid__item label
{
	/*font-size: 16px;*/
	margin-right: 10px;
	
}

.subscribe__email-input
{
	flex-grow: 1;
	max-width: 500px;
}
form.article-grid__item input[type='email']
{
	padding: 10px 10px;
	width: 80%;
	border: 2px solid rgb(238, 238, 238);
	font-size: 16px;
}

.subscribe__email-submit .btn
{
	/*display: inline-block;*/
	padding: 0;
	padding: 10px 10px;
	background: #fff;
	color: #0055ff;
	font-size: 16px;
	font-weight: 700;
	border: 2px solid rgb(238, 238, 238);
	border-left: none;
}
/*===*/




/* Default listing */
.article-listing
{
	max-width: 1000px;
	list-style: none;
	margin: 0 auto 60px auto;
	padding-left: 0;
	
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.article-listing_item
{
	width: 100%;
	margin-bottom: 40px;
	padding: 20px;
	border-radius: 12px;
	background: #fefefe;
	border: 1px solid #f1f0e7;
	border: 1px solid #ffe3d9;
	
	&:hover
	{
		border: 1px solid #ffe881;
	}

	header
	{
		margin-top: 10px;
		margin-bottom: 30px;
	}
	h2
	{
		color: #443629;
		font-size: 24px;
		@media screen and (min-width: 700px) {
			font-size: 34px;
		}
	}

	.date
	{
		margin-top: 8px;
		font-size: 0.8em;
	}
	
}
/**/




/* Paging */
.paging.wide
{
	max-width: 1300px;
}

.paging
{
	max-width: 1000px;
	margin: 60px auto;
	height: 40px;

	.btn
	{
		background-color: #ff7043;
		border-color: #ff7043;
		border-radius: 4px;
		&:hover
		{
			background-color: #de491a;
			border-color: #de491a;
		}
	}
}

.paging__prev
{
	float: left;
}
.paging__next
{
	float: right;
}
/**/





/* Post */
article.h-entry
{
	margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
	max-width: 1000px;
	@media screen and (min-width: 1300px)
	{
		margin-top: 120px;
	}

	.h-entry__title
	{
		position: relative;
		margin-top: 20px;
		margin-bottom: 20px;
		z-index: 99;
		color: #443629;
		font-size: 2.2rem;
		font-size: calc(34px + 10 * ((100vw - 500px) / 1500));
		@media screen and (min-width: 500px)
		{
			font-size: calc(38px + 3 * ((100vw - 500px) / 1500));
		}

		a
		{
			background-size: 96% 32%;
			background-position: -20px 100%;
			padding-left: 15px;
			margin-left: -15px;
		}
	}
	
	
	
	.meta
	{
		max-width: initial;
	}

	.description
	{
		max-width: 1000px;
		margin: 20px auto;
		
		h1, h2, h3, h4, h5, h6
		{
			position: relative;
			margin-top: 40px;
		}

		h2
		{
			font-size: 1.25em;
			margin-top: 100px;
		}
		h3
		{
			font-size: 1.1em;
		}
		h4
		{
			font-size: 1.05em;
		}
		
		ul,	ol
		{
			padding-left: 9%;
			max-width: 750px;
			line-height: 1.8;
			margin-bottom: 32px;

			li {
				margin-bottom: 6px;
			}
		}

		a
		{
			color: #007ada;
		}

		pre
		{
			max-width: 950px;
			margin-bottom: 32px;
			padding-top: 40px;
			padding-bottom: 40px;
			padding-left: 8%;
			border-radius: 0;
			
			font-size: 17px;
			font-size: calc(16px + 1 * ((100vw - 500px) / 1500));
		}
	}

	.description p,
	.description h2,
	.description h3,
	.description h4,
	.h-entry__title,
	.meta
	{
		padding-left: 6%;
		padding-right: 6%;
	}
	
	p
	{
		max-width: 750px;
		line-height: 1.65;
		margin-bottom: 32px;
	}

	footer
	{
		max-width: 1000px;
		margin: 80px auto;
	}

	.tags
	{
		li
		{
			margin-bottom: 8px;
		}
	}
}
/**/






/* Author */
.author-card
{
	border-top: 2px solid #eae6d3;
	padding: 10px 30px;
	padding: 10px 6%;
	margin-top: 30px;
	max-width: 750px;
	
	display: flex;
	align-items: center;
	/*justify-content: center;*/
}	

.author-card__avatar, 
.author-card__details 
{
	display: inline-block;
	vertical-align: middle;
}

.author-card__avatar
{
	width: 68px;
	height: 68px;
	margin-right: 20px;
	
	margin-top: 20px;
	align-self: flex-start;

	@media screen and (min-width: 450px)
	{
		width: 90px;
		height: 90px;
	}
}

.author-card__details
{
	h3
	{
		font-size: 1.1em;
		margin-bottom: 5px;
	}

	p
	{
		margin-top: 0;
		font-size: 0.95em;
		max-width: 450px;
	}
}

.author-card__link
{
	padding: 4px 8px;
	border-radius: 3px;
	color: #fff;
	font-size: 0.8em;
	background-color: #5c5c5c;
	transition: background;
	transition-duration: 0.15s;
	transition-timing-function: ease-in-out;
	&:hover
	{
		background-color: #363636;
	}
}
.author-card__link.twitter
{
	background-color: #1da1f2;
	&:hover
	{
		background-color: #107dbc;
	}
}	
/**/
