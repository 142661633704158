.pipits-listing
{
	//padding: 40px 5px 0 5px;
	max-width: 1800px;
	margin: 0 auto;
    text-align: left;

    @media screen and (min-width: 1140px)
    {
        //padding: 40px 0 0 0;
    }

    ul
    {
        padding: 0;
        text-align: left;
        margin: 60px auto;
        list-style: none;
        
        max-width: 1300px;
        
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }


    
    li
    {
        @media screen and (min-width: 700px)
        {
            
            width: 49%;
        }

        @media screen and (min-width: 950px)
        {
            width: 32%;
        }

        padding: 0 25px;
        margin-bottom: 2em;	
        background: #b9f2e9;
        background-image: linear-gradient(0deg, #effffe 0%, #ddffff 100%);
        border: 1px solid #f1f0e7;
        border-radius: 12px;
        

        h2 {
            font-size: 24px;
            color: #443629;
        }
        p
        {
            font-size: calc(16px + 1 * ((100vw - 500px) / 1500));
            font-size: 1.05em;
            max-width: 450px;
        }

        .btn
        {
            font-size: calc(16px + 1 * ((100vw - 500px) / 1500));
            margin-right: 3%;
            margin-top: 30px;
            margin-bottom: 20px;
            &:last-child
            {
                margin-right: 0;
            }
        }
    }
    
}


.secondary-fonts-loaded .pipits-listing
{
    p, h2
    {
        font-family: PT-Sans, Roboto, Arial, Helvetica, sans-serif;
    }
}


.pipits-listing_details
{
    padding: 0 8%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pipits-listing_details-remarks {
    border-radius: 12px;
    background-color: #fffcf3;
    background-color: #fffefd;
    //border: 1px solid #fff2b4;
    border: 1px solid #ffd5d5;
    padding: 0.4rem 0.6rem;
    font-size: 0.85rem;
    display: inline-block;
    p {
        margin: 0;
    }

    a {
        color: #ef5350;
    }
}








@media screen and (min-width: 1000px)
{
	.pipits-listing_details
	{
		padding: initial;
    }
	
	.pipits-listing li
	{
		/*padding: 140px 10px;
        flex-direction: row;*/
        .btn
        {
            margin-right: 1%;
        }
	}
}
