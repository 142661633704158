.docs-wrapper
{
	margin-top: 40px;
	margin-bottom: 80px;

	main
	{
		max-width: 900px;
		min-width: 0;
		flex: 1 1 auto;

		h1, h2, h3, h4, h5, h6
		{
			position: relative;
		}

		pre
		{
			border-radius: 0;
			font-size: 17px;
			font-size: calc(17px + 1 * ((100vw - 500px) / 1500));
			padding-top: 40px;
			padding-bottom: 40px;
			padding-left: 8%;
		}

		h1
		{
			margin-top: 0;
		}

		h2
		{
			margin-top: 80px;
		}

		h3
		{
			margin-top: 35px;
			margin-bottom: 10px;
		}

		h3+p
		{
			margin-top: 0;
		}

		p,
		h1,
		h2,
		h3,
		h4,
		ul, ol,
		table
		{
			padding: 0 6%;
		}

		ul, ol
		{
			list-style-position: inside;

			li h2,
			li h3,
			li h4,
			{
				display: inline;
				padding: 0;
			}
		}

		table
		{
			width: 100%;
			border-collapse: collapse;
			border-spacing: 0;
			margin-bottom: 1rem;
			border-radius: 0;
			font-size: 17px;

			th, td
			{
				padding: 0.5rem 0.625rem 0.625rem;
				text-align: left;
			}
		}
		
		thead,
		tfoot
		{
			border: 1px solid #f1f1f1;
			background-color: #fff2b4;
		}
		
		tbody
		{
			border: 1px solid #f1f1f1;
			background-color: #fffcf3;
			
			tr
			{
				&:nth-child(even)
				{
					background-color: #faf2cb;
				}
			}
		}
		
		thead,
		tfoot
		{
			tr
			{
				background: transparent;
			}
		
			th, td
			{
				padding: 0.5rem 0.625rem 0.625rem;
				font-weight: 700;
				text-align: left;
			}		
		}
	}
}


nav.sidebar
{
	background-color: #fffcf3;
	margin: 50px 6%;

	ul
	{
		list-style: none;
		margin: 8px 0;
		margin: 0;
		padding: 0;
	}

	li ul
	{
		display: none;
	}

	li.selected,
	li.ancestor
	{
		ul
		{
			display: block;
			background-color: #faf2cb;
		}
	}

	h3, li
	{
		margin: 0;
		border-bottom: 1px solid #fff2b4;
		border-left: 5px solid #fff2b4;
		font-size: 0.9em;
	}

	li
	{
		&:last-child
		{
			border-bottom: 0;
		}

		a
		{
			padding: 8px 25px;
			display: block;
		}

		li
		{
			border-left: 0;
			padding-left: 15px;
			border-bottom: 1px solid #fffcf3;

			&:hover a
			{
				text-decoration: underline;
				background-color: #fff2b4;
			}
		}
	}

	
	h3
	{
		a
		{
			padding: 20px 15px 8px 15px;
			display: block;
		}
	}
	
	li a:hover,
	h3:hover
	{
		background-color: #fff2b4;
	}
}




@media screen and (min-width: 640px)
{
	.docs-wrapper
	{
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin: 40px auto 100px 5%;
		padding-right: 20px;
	}
	
	main
	{
		p,
		h1,
		h2,
		h3,
		h4,
		ul, ol,
		table
		{
			padding: 0;
		}
	}
	
	nav.sidebar
	{
		margin-right: 5%;
		margin: 0 5% 50px 0;
		flex: 0 0 200px;
	}
}
@media screen and (min-width: 830px)
{
	nav.sidebar
	{
		flex: 0 0 240px;
	}
}