.pipits
{
    padding-top: 130px;
	text-align: center;
    background-image: linear-gradient(0deg, #fffcf3 0%, #d2aa6a 90%, #d2aa6a 100%);

    &.is-plain {
        background: none;
        padding-top: 0;
        ul {
            margin: 20px auto;
        }
        li a {
            padding: 10px;
            font-size: 18px;
        }
    }


    @media screen and (min-width: 800px)
    {
        padding-top: 300px;        
    }

    p
    {
        font-size: 1.4em;
        max-width: 520px;
        margin: 0 auto;
    }

    ul
    {
        list-style: none;
        margin: 60px auto;
        padding: 0;
        
        /*max-width: 1000px;*/
        max-width: 1250px;
        
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    li
    {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-bottom: 2em;
        background: #fff;
        border: 1px solid #e0e0e0;
        
        font-size: 1.1em;
        box-shadow: 0 0 40px rgba(0,0,0,0);
        transition: box-shadow;
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;

        @media screen and (min-width: 500px)
        {
            width: 48%;
        }
        @media screen and (min-width: 800px)
        {
            /*width: 30%;*/
            width: 23%;
        }


        &:hover
        {
            box-shadow: 0 0 40px rgba(0,0,0,.15);
        }

        &:before
        {
            content: '';
            position: absolute;
            top: -6px;
            left: -1px;
            right: -1px;
            height: 6px;
            
            background: #3cb371;
            /*
            purple = #483d8b
            orange = #ffb700
            brown = #847e6e
            red = #f44336
            green = #3cb371
            blue = #1976d2
            */
        }

        a
        {
            display: block;
            padding: 16px;
        }
    }
    .apps:before
    {
        background: #f44336;
    }
    .other:before
    {
        background: #1976d2;
    }
    .template-filters:before
    {
        background: #483d8b;
    }   
}
.secondary-fonts-loaded .pipits p
{
    font-family: PT-Sans, Roboto, Arial, Helvetica, sans-serif;
}